<template>
  <div class="Serve">
    <div class="banner">
      <div class="container">
      </div>
    </div>
    <div class="contts pd40">
      <div class="container">
        <el-row :gutter="24">
          <el-col :span="6">
            <div v-for="(item,index) in navlist"
                 :key='index'>
              <h3 class="">{{item.name}}</h3>
              <div class="lists">
                <router-link :to="item2.link"
                             v-for="(item2,index) in item.erji"
                             :key='index'>{{item2.name}}</router-link>
              </div>
            </div>
          </el-col>
          <el-col :span="18">
            <router-view></router-view>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      navlist: [
        {
          name: "Products and Services",
          link: '/',
          erji: [{
            name: 'International Student Insurance',
            link: '/baoxian'
          },
          {
            name: 'Official Textbooks',
            link: '/jiaocai'
          }, {
            name: 'SIM Card',
            link: '/SIM-Card'
          }, {
            name: 'Accommodation Reservation Service',
            link: '/zhusu'
          }, {
            name: 'Airport Pickup Service',
            link: '/jieji'
          }, {
            name: 'International Student Service',
            link: '/xuexiao'
          }]
        }
      ],
    }
  },
  beforeMount () {

  }
}
</script>

<style lang="scss" scoped>
.banner {
  background: url(~@/assets/neibanner3.jpg) top center no-repeat;
  height: 400px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .container {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    .title {
      font-size: 38px;
      font-weight: bold;
      color: #fff;
      letter-spacing: 4px;
    }
  }
}
.contts {
  h3 {
    background-color: #004168;
    color: #fff;
    padding: 20px 0;
    text-align: center;
  }
  .lists {
    border: 1px solid #dedede;
    a {
      display: block;
      padding: 10px;
      text-align: center;
      border-bottom: 1px solid #dedede;
      &:last-child {
        border-bottom: none;
      }
      &:hover {
        background-color: #dedede;
      }
    }
  }
}
</style>